const isBrowserOutdated = () => {
  const ua = window.navigator.userAgent;
  const minimumSupportedBrowsers = {
    Chrome: 85,
    Firefox: 80,
    Safari: 14.1,
    'Mobile Safari': 15.4,
    Edge: 85,
    IE: 11,
  };

  const getVersion = match => (match ? parseFloat(match[1]) : null);

  if (/MSIE|Trident/.test(ua)) {
    const match = ua.match(/(MSIE |rv:)(\d+(\.\d+)?)/);
    const version = match ? parseFloat(match[2]) : null;
    return version !== null && version < minimumSupportedBrowsers.IE;
  }

  if (/Chrome/.test(ua) && !/Edge/.test(ua)) {
    const match = ua.match(/Chrome\/([\d\.]+)/);
    const version = getVersion(match);
    return version !== null && version < minimumSupportedBrowsers.Chrome;
  }

  if (/Firefox/.test(ua)) {
    const match = ua.match(/Firefox\/([\d\.]+)/);
    const version = getVersion(match);
    return version !== null && version < minimumSupportedBrowsers.Firefox;
  }

  if (/Edge/.test(ua)) {
    const match = ua.match(/Edge\/([\d\.]+)/);
    const version = getVersion(match);
    return version !== null && version < minimumSupportedBrowsers.Edge;
  }

  if (/Safari/.test(ua) && !/Chrome/.test(ua)) {
    const match = ua.match(/Version\/([\d\.]+)/);
    const version = getVersion(match);
    if (/iPhone|iPad|iPod/i.test(ua)) {
      return version !== null && version < minimumSupportedBrowsers['Mobile Safari'];
    } else {
      return version !== null && version < minimumSupportedBrowsers.Safari;
    }
  }

  return false;
};

export default isBrowserOutdated;
