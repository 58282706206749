import { useEffect, useLayoutEffect, useState } from 'react';
import Svg from 'react-inlinesvg';
import { useDispatch, useSelector } from 'react-redux';
import chromeIcon from '../../assets/svg/browsers/chrome.svg';
import edgeIcon from '../../assets/svg/browsers/edge.svg';
import firefoxIcon from '../../assets/svg/browsers/firefox.svg';
import safariIcon from '../../assets/svg/browsers/safari.svg';
import { handleHeaderColorScheme, isLoadingSelector } from '../../entities/ComponentsSlice';
import AnimateLines from '../../features/animate-lines/AnimateLines';
import styles from './NotSupportedPage.module.scss';
import { isMobileOnly } from 'react-device-detect';

const NotSupportedPage = () => {
  const [toggle, setToggle] = useState(false);
  const icons = [
    { icon: edgeIcon, id: 0 },
    { icon: chromeIcon, id: 1 },
    { icon: firefoxIcon, id: 2 },
    { icon: safariIcon, id: 3 },
  ];
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const minimumSupportedBrowsers = {
    Chrome: 85,
    Firefox: 80,
    Safari: 14.1,
    'Mobile Safari': 15.4,
    Edge: 85,
    IE: 11,
  };

  useEffect(() => {
    dispatch(handleHeaderColorScheme('blackOnTransparent'));
  }, []);

  useLayoutEffect(() => {
    if (isLoading) setTimeout(() => setToggle(true), 500);
  }, [isLoading]);

  return (
    <section className={styles.wrapper}>
      {!isMobileOnly && <AnimateLines id='notSupported' className={styles.svgLine} toggle={toggle} />}
      {isMobileOnly && <AnimateLines id='notSupportedMob' className={styles.svgLine} toggle={toggle} />}
      <div className={styles.content}>
        <div className={styles.textBox}>
          <p className={styles.title}>Устаревший браузер</p>
          <p className={styles.description}>
            Рекомендуем обновить браузер{isMobileOnly && <br />} или использовать последние версии
          </p>
        </div>
        <div className={styles.browsers}>
          {icons.map(item => {
            return (
              <div key={item.id} className={styles.browsers__icon}>
                <Svg src={item.icon} />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default NotSupportedPage;
