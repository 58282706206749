import { useMemo, useState } from 'react';
import { animated, useSpring } from 'react-spring';

import { vectorConfigs } from './vectorConfigs';

const useAnimatedStyle = (length, toggle, animationType) => {
  const baseConfig = {
    strokeDasharray: length,
    strokeDashoffset: toggle ? 0 : length,
  };

  const configs = {
    default: {
      ...baseConfig,
      config: { duration: 800, mass: 1, tension: 280, friction: 120 },
      delay: 1000,
    },
    trans: {
      ...baseConfig,
      config: { duration: 800, mass: 1, tension: 280, friction: 120 },
      delay: 200,
    },
    long: {
      ...baseConfig,
      config: { duration: 8000, mass: 1, tension: 280, friction: 120 },
      delay: 1000,
    },
    medium: {
      ...baseConfig,
      config: { duration: 3000, mass: 1, tension: 280, friction: 120 },
      delay: 1000,
    },
  };

  return useSpring(configs[animationType] || configs.default);
};

const AnimatedPath = ({ d, stroke, style, setLength }) => (
  <animated.path
    style={style}
    ref={ref => {
      if (ref) {
        setLength(ref.getTotalLength());
      }
    }}
    d={d}
    stroke={stroke}
  />
);

const VectorSvg = ({ viewBox, children, className, preserveAspectRatio = 'xMinYMin meet' }) => (
  <svg
    viewBox={viewBox}
    fill='none'
    preserveAspectRatio={preserveAspectRatio}
    xmlns='http://www.w3.org/2000/svg'
    className={className}
  >
    {children}
  </svg>
);

function AnimateLines({ id, toggle, className }) {
  const [length, setLength] = useState(null);
  const config = useMemo(() => vectorConfigs[id], [id]);
  const animationType = useMemo(
    () => config?.animationType || (id.includes('_mob') ? 'trans' : 'default'),
    [config, id],
  );
  const animatedStyle = useAnimatedStyle(length, toggle, animationType);

  if (!config) return null;

  return (
    <VectorSvg viewBox={config.viewBox} className={className} preserveAspectRatio={config.preserveAspectRatio}>
      <AnimatedPath d={config.d} stroke={config.stroke} style={animatedStyle} setLength={setLength} />
    </VectorSvg>
  );
}

export default AnimateLines;
