import { useEffect } from 'react';
import { useLocomotiveScroll } from 'react-locomotive-scroll';
import { useDispatch, useSelector } from 'react-redux';
import { handleHeaderColorScheme } from '../../entities/ComponentsSlice';
import { deviceSelector } from '../../entities/UserSlice';

const ScrollHelperStandart = () => {
  const dispatch = useDispatch();
  const { scroll } = useLocomotiveScroll();
  const device = useSelector(deviceSelector);
  useEffect(() => {
    dispatch(handleHeaderColorScheme(''));
    return function () {
      dispatch(handleHeaderColorScheme(''));
    };
  }, []);
  useEffect(() => {
    if (device === 'isMobile' || !scroll) {
      dispatch(handleHeaderColorScheme(''));
    }
    if (scroll) {
      scroll.on('scroll', () => {
        dispatch(handleHeaderColorScheme(''));
      });
    }
  }, [scroll, device]);

  return null;
};

export default ScrollHelperStandart;
