import { Suspense } from 'react';
import LoaderCircle from '../shared/loader-circle/LoaderCircle';
import useSeoShortUrl from '../tools/hooks/useSeoShortUrl';

const SeoRouteGuard = ({ children }) => {
  const { isChecking, shouldRedirect } = useSeoShortUrl();

  if (isChecking || shouldRedirect) {
    return <LoaderCircle />;
  }

  return <Suspense fallback={<LoaderCircle />}>{children}</Suspense>;
};

export default SeoRouteGuard;
