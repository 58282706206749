export const vectorConfigs = {
  vector_1: {
    viewBox: '0 0 1490 828',
    stroke: '#EDEEED',
    d: 'M-4.5 1L175 1C253.5 515.5 1110 464.5 1484.5 487C1487.26 486.608 1488.62 486.235 1488.51 485.879C1459.19 391.216 1380 353.5 1126 389C822.57 433.878 342.177 570.577 -152 880',
  },
  vector_2: {
    viewBox: '0 0 892 660',
    stroke: '#FFFFFF',
    d: 'M0 1H75.5C59 638.5 922.344 483.5 889.5 128.5C874.034 -38.6726 748.5 92.0003 607.5 665.5',
  },
  vector_3: {
    viewBox: '0 0 1337 1359',
    stroke: '#C4C4C4',
    d: 'M-58 -236C79.0001 423 1217 406.5 1333 140C1381.33 28.9614 902.5 253.5 1005.5 506.5C1018.69 538.9 1097 606 1203.5 583L340 1706.5',
  },
  vector_4: {
    viewBox: '0 0 1199 649',
    stroke: '#8EA88D',
    d: 'M-154 1L694.5 84.5C674.5 91.5 557.521 189.689 565.284 308.263C581.971 563.16 1291.81 401.058 1188 319.5C1087.78 240.763 593.85 318.93 356 732.5',
  },
  vector_5: {
    viewBox: '0 0 560 1964',
    stroke: '#C4C4C4',
    d: 'M1242.05 1L207.745 949.986C229.33 947.081 356.746 959.088 384.461 1057.76C455.499 1310.67 -51.2607 1480.95 5.79642 1357.81C60.8806 1238.93 1031.3 1091.79 1292.81 1963.28',
  },
  vector_6: {
    viewBox: '0 0 832 1178',
    stroke: '#636363',
    d: 'M1046 336.127C892.5 508.627 550 596.126 256 301.126C-65.8023 -21.7701 180.387 -103.094 332.5 150.626C459 361.625 510.5 705.125 1 755.627L537 1635',
  },
  vector_7: {
    viewBox: '0 0 255 580',
    stroke: '#FFFFFF',
    d: 'M185.332 -356C234.831 123 -316.669 249 282.332 579.5',
  },
  vector_8: {
    viewBox: '0 0 858 1185',
    stroke: 'white',
    d: 'M983.878 206.634L755.768 81.9868C760.095 100.539 798.672 257.585 717.879 286.487C510.801 360.562 329.583 -39.3246 438.378 3.98647C543.412 45.7999 761.296 908.703 0.278972 1184.43',
  },
  vector_9: {
    viewBox: '0 0 858 1185',
    stroke: '#E0E0E0',
    d: 'M1300.01 168.428L569.444 768.087C579.634 746.923 626.538 597.448 527.507 510.586C314.62 323.861 -114.729 1007.34 30.5715 989.905C170.847 973.068 422.249 511.699 284.508 1.07246',
  },
  vector_10: {
    viewBox: '0 0 993 954',
    stroke: '#838383',
    d: 'M991.5 371.5C993.366 368.13 472.5 745 246.5 745C-37.5003 745 302.5 302 371 843.184C439.5 1384.37 -1280.44 -269.16 -1531 40.1857',
  },
  vector_11: {
    viewBox: '0 0 1600 1497',
    stroke: '#838383',
    d: 'M1996.6 1381.04C2025.42 1234 1796.75 980.236 1678.81 871.736C1577.9 778.896 1131.79 1489.48 1347.29 1495.69C1677.23 1505.19 238.206 348.915 107.108 181.731L-405.682 1.00006',
  },
  vector_arch_1: {
    viewBox: '0 0 1600 1118',
    stroke: '#EDEEED',
    d: 'M657 1C735.5 515.5 1592 464.5 1966.5 487C1969.26 486.608 1970.62 486.235 1970.51 485.879C1941.19 391.216 1862 353.5 1608 389C1304.57 433.878 405.5 617 -10.5 1117',
  },
  vector_arch_2: {
    viewBox: '0 0 986 1080',
    stroke: '#E1E1E1',
    d: 'M941.502 1.00049C588.419 244.939 -6.41765 1004.04 1.49992 799.5C19.4999 334.5 1225.5 986.2 941.502 1079',
  },
  vector_arch_3: {
    viewBox: '0 0 1382 5471',
    stroke: '#C4C4C4',
    d: 'M377.159 1C726.493 102.333 1518.66 -188 1360.66 286C1217.46 794.8 874.159 1044.5 935.659 1749C1220.66 1115 255.379 1704.03 654.674 2166C1333.17 2951 607.466 2613.51 619.159 2786.5C657.326 3351.18 -471.73 3289.37 -525.84 3109.5C-598.341 2868.5 1178.1 3673.33 1256.16 4584C1310.16 5214 17.6592 4295.58 274.659 4209.5C583.659 4106 -1180.34 5499.5 -207.341 5469.5',
    animationType: 'long',
  },
  vector_landscp_1: {
    viewBox: '0 0 1600 1043',
    stroke: '#C4C4C4',
    d: 'M-26.5004 1042C-260 620.5 1186.39 481.5 1302.39 748C1350.72 859.039 922.105 665.281 901 474.5C867.489 171.57 1722.28 139 1656.28 1',
    animationType: 'trans',
  },
  vector_landscp_3: {
    viewBox: '0 0 1600 3627',
    stroke: '#C4C4C4',
    d: 'M1659.5 0.5C2743.5 81 158.913 1808.7 0.555908 1381.5C-107.5 1090 3457 1835.5 2302.5 2247.5C2269.55 2259.26 1167.92 2955 1061.42 2932L868.057 3626.5',
  },
  vector_landscp_5: {
    viewBox: '0 0 883 1586',
    stroke: '#C4C4C4',
    d: 'M909 1C-1226.5 1033.5 1193.53 906.079 640.752 775.609C463.669 733.813 -220.538 1256.5 72.9623 1585',
    preserveAspectRatio: 'xMinYMin meet',
  },
  vector_landscp_mob: {
    viewBox: '0 0 375 257',
    stroke: '#DEDEDE',
    d: 'M-178 380.499C-216.729 243.838 304 152.499 352 232.5C370.976 264.127 264.501 224.356 261 162.499C255.442 64.2815 420.447 45.2424 409.5 0.499268',
  },
  'infrastructure-2': {
    viewBox: '0 0 1511 1039',
    stroke: '#C4C4C4',
    d: 'M1579.36 -0.000319971C1581.1 178.404 1449.58 740.964 1266.83 553.444C1084.09 365.924 1498.63 485.725 1498.63 664.563C1498.63 843.402 0.500035 1038 0.500035 1038',
  },
  'infrastructure-2-mob': {
    viewBox: '0 0 375 344',
    stroke: '#C4C4C4',
    d: 'M-39.5765 78.2758C-90.7 -95.5837 402.787 84.8174 356.924 42.7759C248.158 -56.9279 348.153 197.434 479.235 343.46',
  },
  'infrastructure-4': {
    viewBox: '0 0 719 424',
    stroke: '#C4C4C4',
    d: 'M393.5 0.999211C400.999 206.5 18.9913 371.161 1.1512 296.719C-20.3617 206.95 600 201.5 718 423.5',
  },
  'infrastructure-5': {
    viewBox: '0 0 1597 837',
    stroke: '#C4C4C4',
    d: 'M1688.5 727.499C1971.49 1095.85 -49 401.5 543.999 496.498C977 565.864 207.717 859.861 71.9999 744.994C-206.896 508.945 390.276 76.1447 949.513 0.752839',
  },
  'infrastructure-5-mob': {
    viewBox: '0 0 375 490',
    stroke: '#C4C4C4',
    d: 'M-63.499 0.500166C-135.091 85.0371 -67.0255 236.704 -33.999 248.001C176.501 320.001 92.001 38.5008 20.001 94.5008C-38.5068 140.007 353.489 25.0885 263.799 8.62117C285.033 310.837 424.001 672.768 453.001 537.768',
  },
  'location-1': {
    viewBox: '0 0 1540 1233',
    stroke: '#C4C4C4',
    d: 'M421.765 1232.5C991.266 888 120.01 -222.842 2.76514 73.5C-60.7349 234 1609.77 160.5 1646.53 1',
  },
  'location-1-mob': {
    viewBox: '0 0 369 266',
    stroke: '#C4C4C4',
    d: 'M468.5 265C599.668 185.554 28.4103 -13.6209 1.40649 54.7195C-13.2188 91.7328 404.531 37.2828 413 0.5',
  },
  'about-desktop': {
    viewBox: '0 0 1553 2988',
    stroke: '#C4C4C4',
    d: 'M1540 1C1703 779 177.624 643.665 53.675 377.097 2.03 266.03 602.559 470.435 492.5 723.5c-14.094 32.409-186.651 119.717-300.45 96.711L1114.73 1944C683.484 2001.17-149.9 2289.8-33.5 2987',
  },
  'about-green-oasis-line': {
    viewBox: '0 0 1382 5471',
    stroke: '#C4C4C4',
    d: 'M377.159 1C726.493 102.333 1518.66 -188 1360.66 286C1217.46 794.8 874.159 1044.5 935.659 1749C1220.66 1115 255.379 1704.03 654.674 2166C1333.17 2951 607.466 2613.51 619.159 2786.5C657.326 3351.18 -471.73 3289.37 -525.84 3109.5C-598.341 2868.5 1178.1 3673.33 1256.16 4584C1310.16 5214 17.6592 4295.58 274.659 4209.5C583.659 4106 -1180.34 5499.5 -207.341 5469.5',
  },
  'about-mobile': {
    viewBox: '0 0 150 913',
    stroke: '#C4C4C4',
    d: 'M234 -49C136.5 22.1667 270.854 202.652 178.501 477.5C95.0011 726 -83.4994 746.5 47.5006 673C178.501 599.5 134.792 916.333 178.501 912',
  },
  'about-mobile-queue': {
    viewBox: '0 0 364 740',
    stroke: '#C4C4C4',
    d: 'M234.046 1c-97.5 71.167 36.854 251.652-55.499 526.5C95.047 776-56.735 772.5 24.5 683c81.235-89.5 339.791-54.667 383.499-59',
  },
  'commercial-line': {
    viewBox: '0 0 1278 991',
    stroke: '#C4C4C4',
    d: 'M1300.01 168.428L569.444 768.087C579.634 746.923 626.538 597.448 527.507 510.586C314.62 323.861 -114.729 1007.34 30.5715 989.905C170.847 973.068 422.249 511.699 284.508 1.07246',
  },
  'commercial-line-mobile': {
    viewBox: '0 0 375 315',
    stroke: '#C4C4C4',
    d: 'M420.504 -60.4639C648.56 -35.4753 221.028 322.601 292.5 289.5C462 211 143.814 231.691 -90.6848 313.692',
  },
  'white-box-general': {
    viewBox: '0 0 1600 1788',
    stroke: '#C4C4C4',
    d: 'M1539.81 1L1190.22 24.5955C1524.85 218.29 1802.1 1227.58 748.003 1147.3C-390.946 1060.55 -96.9469 575.134 748.003 844.013C1440.14 1064.26 1790.15 1574.19 1924 1787',
  },
  school: {
    viewBox: '0 0 1598 2988',
    stroke: '#C4C4C4',
    d: 'M1585.53 1C1748.53 779 223.156 643.665 99.2062 377.097C47.562 266.03 648.09 470.435 538.032 723.5C523.937 755.909 351.38 843.217 237.581 820.211L1160.26 1944C729.015 2001.17 -104.368 2289.8 12.0317 2987',
    animationType: 'medium',
  },
  'school-mob': {
    viewBox: '0 0 370 2179',
    stroke: '#C4C4C4',
    d: 'M324.5 1C521.5 345.5 6.91184 581.5 0.999542 538.5C-2.64408 512 353.751 494.171 363.999 677.5C372.999 838.5 58.0813 811.978 31.9995 797L359.5 1470C84.4995 1470 -21.6785 1724.6 4.99951 2178.5',
    animationType: 'medium',
  },
  vector_1_mob: {
    viewBox: '0 0 356 495',
    stroke: '#EDEEED',
    d: 'M21.1934 1H354.5C-288.5 687 927.5 506 -60 349',
    animationType: 'trans',
  },
  vector_2_mob: {
    viewBox: '0 0 375 728',
    stroke: '#C4C4C4',
    d: 'M459 -68C361.5 3.16666 188.4 214.6 276 491C385.5 836.5 543.5 734.059 359.5 615C175.5 495.941 137.5 674.454 170 704C172.589 706.354 -543 378.5 -543 378.5',
  },
  vector_3_mob: {
    viewBox: '0 0 375 690',
    stroke: '#8EA88D',
    d: 'M402.373 -125C377.371 144 95.3716 551 -163.126 543C-431.743 534.687 138.87 170 291.871 338.5C405.13 463.233 326.706 626.167 282.373 688.5L1053.37 601.5',
  },
  vector_4_mob: {
    viewBox: '0 0 277 221',
    stroke: '#636363',
    d: 'M152.009 -72C185.842 -42.1667 221.01 57 98.0089 151C-53.0543 266.446 -16.49 133 130.509 104.5C211.51 88.7956 234.01 177 226.009 219.5L529.509 69.5',
  },
  vector_5_mob: {
    viewBox: '0 0 375 567',
    stroke: '#E0E0E0',
    d: 'M267.5 -12C163 382.5 310.5 581.784 372.5 549.5C408.778 530.61 343.697 432.836 273.5 400C180.191 356.353 71.7074 394.583 120.5 565.5L-232 430',
  },
  vector_6_mob: {
    viewBox: '0 0 375 285',
    stroke: '#C4C4C4',
    d: 'M547.636 38C561.423 54.5366 284.812 88.9002 300.136 202C323.136 371.757 410.979 232.602 261.523 185.199C112.068 137.795 -407.715 45.9984 -466.215 1',
  },
  notSupported: {
    viewBox: '0 0 1555 780',
    stroke: '#C4C4C4',
    d: 'M1662.36 -1033C2746.36 -952.5 161.769 775.197 3.41187 348C-104.644 56.5 3459.86 802 2305.36 1214C2272.41 1225.76 1170.78 1921.5 1064.28 1898.5L870.913 2593',
  },
  notSupportedMob: {
    viewBox: '0 0 375 382',
    stroke: '#DEDEDE',
    d: 'M-178.944 381C-217.673 244.339 303.055 153 351.055 233.001C370.031 264.628 263.556 224.856 260.056 163C254.497 64.7822 419.503 45.7432 408.556 1',
  },
};
