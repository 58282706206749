import { createSlice } from '@reduxjs/toolkit';

const equalizer = createSlice({
  name: 'equalizer',
  initialState: {
    isEqualizerOpen: false,
    selectedBenefit: {},
    currentData: {
      currentFirstPayment: 0,
      currentLoanTerm: 0,
      currentMinMonthFee: 0,
      currentBenefitPrice: 0,
      currentMortgageBenefit: {},
    },
  },
  reducers: {
    setEqualizerOpened(state, action) {
      state.isEqualizerOpen = action.payload;
    },
    setSelectedBenefit(state, action) {
      state.selectedBenefit = action.payload;
    },
    setCurrentData(state, action) {
      const { currentFirstPayment, currentLoanTerm, currentMinMonthFee, currentBenefitPrice, currentMortgageBenefit } =
        action.payload;
      state.currentData.currentFirstPayment = currentFirstPayment;
      state.currentData.currentLoanTerm = currentLoanTerm;
      state.currentData.currentMinMonthFee = currentMinMonthFee;
      state.currentData.currentBenefitPrice = currentBenefitPrice;
      state.currentData.currentMortgageBenefit = currentMortgageBenefit;
    },
    resetSelectedBenefit(state) {
      state.selectedBenefit = {};
      state.currentData = {
        currentFirstPayment: 0,
        currentLoanTerm: 0,
        currentMinMonthFee: 0,
        currentBenefitPrice: 0,
        currentMortgageBenefit: {},
      };
    },
  },
});

export const { setSelectedBenefit, setActiveSlide, setCurrentData, resetSelectedBenefit, setEqualizerOpened } =
  equalizer.actions;

export default equalizer.reducer;
