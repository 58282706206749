const booleanSwitch = (state, array, key, value) => {
  if (!state[array]) return;
  const param = value ? value.toString() : '';
  const element = state[array].find(e => e.value === param);
  if (!element) return;
  if (element[key]) element[key] = false;
  else element[key] = true;
  return element;
};

export default booleanSwitch;
