import axios from 'axios';
import { useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../../layout/routes';
import { UNIVERSAL_ADMIN } from '../helpers/constants';

const IS_SEO_FILTER_21010 = process.env.REACT_APP_FF_FORMA_21522 === 'true';

const useSeoShortUrl = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isChecking, setIsChecking] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useLayoutEffect(() => {
    if (!IS_SEO_FILTER_21010) {
      setIsChecking(false);
      return;
    }

    const currentPath = pathname.slice(1);

    const checkShortUrl = async () => {
      try {
        const { data: seoFilters } = await axios.get(`${UNIVERSAL_ADMIN}/seo_filters`);
        const matchingFilter = seoFilters?.find(item => item?.shortUrl === currentPath && item?.active);

        if (matchingFilter) {
          setShouldRedirect(true);
          const { data: seoPage } = await axios.get(`${UNIVERSAL_ADMIN}/seo-page/${currentPath}`);
          const params = seoPage?.characteristics?.map(char => ({
            key: char?.value === '' ? 'advantages' : char?.key,
            value: char?.value === '' ? char?.key : char?.value,
          }));

          const queryString = params?.reduce(
            (acc, { key, value }) => (acc ? `${acc}&${key}=${value}` : `${key}=${value}`),
            '',
          );

          navigate(`${ROUTES.flats.list}?${queryString}`);
          setShouldRedirect(false);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsChecking(false);
      }
    };

    checkShortUrl();
  }, [pathname, navigate]);

  useLayoutEffect(() => {
    setShouldRedirect(false);
  }, [pathname]);

  return { isChecking, shouldRedirect };
};

export default useSeoShortUrl;
